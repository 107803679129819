'use strict';

import React, { useState } from "react";


const PropTypes = React.PropTypes;

function mapIcon(socialMedia) {
    const media = socialMedia.replace(/ /g, '-');
    switch (socialMedia) {
        case 'email':
            return (
                <i className={'fa fa-envelope'}/>
            );
        case 'quora':
            return (
                <i className='fa fa-fw'>
                    <strong className='fa-quora'>Q</strong>
                </i>
            );
        default:
            return (
                <i className={`fa fa-${media}`}/>
            );
    }
}

const SocialMedia = ({ linkClass }) => { 
  return (
    <ul className={ linkClass }>
      <li key="1">
        <a href="https://jmo.junkomoriya.com/?page_id=24&ref=ns">
          <i className="fa fa-envelope"></i>
        </a>
      </li>
      <li key="2">
        <a href="https://www.youtube.com/channel/UCxhSx5bX4N9xxcaMJSkWpPg">
          <i className="fa fa-youtube"></i>
        </a>
      </li>
      <li key="3">
        <a href="https://twitter.com/junkomoriya">
          <i className="fa fa-twitter"></i>
        </a>
      </li>
      <li key="4">
        <a href="https://www.facebook.com/jazz.junko.moriya">
          <i className="fa fa-facebook"></i>
        </a>
      </li>
      <li key="5">
        <a href="https://www.instagram.com/junkomry/?igshid=YmMyMTA2M2Y=">
          <i className="fa fa-instagram"></i>
        </a>
      </li>
    </ul>
  );
};

export default SocialMedia;
