import React, { useEffect, useState }  from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout2 from '../components/layout2'

const BillingPage = () => {
  const [billingInfo, setBillingInfo] = useState({});
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          STRIPE_KEY
        }
      }
    }
  `)

const sampleJson = {
    "subject": "守屋純子オーケストラ2023定期公演申込受付確認メール",
    "event_title": "守屋純子オーケストラ2023定期公演 2023年2月17日(金)18:15開場 19:00開演 (大和田さくらホール)",
    "name": "古田修也",
    "email": "shuya060426@icloud.com",
    "seats": [
               {
		 "sid": "sid-1",
                 "label": "1階 1列 5番",
                 "type": "2",
                 "price": "2500"
             },
             {
		 "sid": "sid-1",
                 "label": "1階 1列 5番",
                 "type": "2",
                 "price": "2500"
             }
    ],
    "total_price": "5,000 円",
    "payment_method": "三菱銀行口座宛振込",
    "zip": "2480034",
    "prefecture": "神奈川県",
    "address0": "鎌倉市津西1-24-18",
    "address1": "",
    "comments": ""
};

  const stripePromise = loadStripe(data.site.siteMetadata.STRIPE_KEY);

  useEffect(() => {
    console.log('useEffect');
    const strJson = JSON.stringify(sampleJson);
    console.log(strJson);
    const encoded = btoa(encodeURIComponent(strJson));
    console.log(encoded.replace(/=/g,''));

    const decoded = JSON.parse(decodeURIComponent(atob(encoded.replace(/=/g,''))))
    console.log(decoded);
    setBillingInfo(decoded);
//    console.log(atob(encoded));
    var element = document.getElementById('home');
//    element.style.height = 'auto';
    element.style['min-height'] = '0px';

    document.getElementById("eventTitle").textContent = "守屋純子オーケストラ2023定期公演 2023年2月17日(金)";
  },[]);

  return (
    <Layout2 pageTitle="スケジュールアーカイブ">
      <section id='work'>
        <div className='row billingPage'>
          <div className='three columns header-col'>
          <h1 id={data.site.siteMetadata.STRIPE_KEY}>
              <span>クレジットカード</span>
            </h1>
          </div>
          <div className='nine columns main-col'>
    <Elements
      stripe={stripePromise}
      fonts={[
        {
          cssSrc:
            "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
        },
      ]}
    >
      <form>
        <ul>
          <li id="eventTitle"></li>
          <li><span className="billingInfoLabel">お名前: </span><span>{ billingInfo.name }</span></li>
          <li><span className="billingInfoLabel">郵便番号: </span><span>{ billingInfo.zip }</span></li>
          <li><span className="billingInfoLabel">住所: </span><span>{ billingInfo.address0 + ' ' + billingInfo.address1 }</span></li>
          <li><span className="billingInfoLabel">座席: </span></li>
          {/*
	      (            { billingInfo.seats.map(({label}) => ( <li><span className="billingInfoSeats">{sid,label,type,price}</span></li> )}
	      )
	  */}
          <li>
            <CardElement
              className="card-field"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#495057",
                    fontFamily: "'Open Sans', sans-serif",
                  },
                },
              }}
            />
          </li>
        </ul>
      </form>
      </Elements>
      </div>
      </div>
          <div className="row schedule-menu-line"><p className="sched-archive"><a href="/#work">&lt;&lt;戻る</a></p></div>
      </section>
    </Layout2>
  )
}

export default BillingPage
