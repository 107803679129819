import React, { useState, useEffect } from 'react'
import SocialMedia from './social_media';

const Banner = ({children}) => {

  useEffect(() => {
    const onLoad = () => {
    };
    onLoad();
  }, []);

  return (
    <div className="row banner">
      <div className="banner-text">
        <h1 className="responsive-headline">
          Junko Moriya Site
        </h1>
        <br/>
        <hr/>
        <SocialMedia linkClass={'social'}/>
      </div>
    </div>
  )
}

export default Banner
