import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Navigation from './navigation'
import Banner from './banner'
import ScrollDown from './scrolldown'
import {
  container,
  heading,
  navLinks,
  navLinkItem,
  navLinkText,
  siteTitle
} from './layout.module.css'


const Layout2 = ({ pageTitle, children }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="app">
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <div data-reactroot className="main-container ya2">
      <Header className="ya2">
        <Navigation/>
      </Header>      
	  {/*      <header className={siteTitle}>{data.site.siteMetadata.title}</header>
      <nav>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <Link to="/" className={navLinkText}>
              Home
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/about" className={navLinkText}>
              About
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/blog" className={navLinkText}>
              Blog
            </Link>
          </li>
        </ul>
      </nav>
      <main>
        <h1 className={heading}>{pageTitle}</h1>
	   */}
        {children}
      {/*
      </main>
       */}
      </div>
    </div>
  )
}

export default Layout2

