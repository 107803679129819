import React from 'react'

const ScrollDown = () => {
  return (
    <p className='scrolldown'>
      <a className='smoothscroll' href='#whatsnew'>
        <i className='icon-down-circle'/>
      </a>
    </p>
  );
}

export default ScrollDown
