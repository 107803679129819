import React, { useState, useEffect } from 'react'

const Header = ({className, children}) => {
  const [dimensions, setDimensions] = useState({ window: { height: 0, width: 0 }});

  useEffect(() => {
    const onLoad = () => {
      if (typeof window === "undefined") {
        console.log('global window is undefined!!');
        return;
      }
      window.addEventListener('resize', updateDimensions);
      updateDimensions();
      console.log(window.location.search);
//      return () => window.removeEventListener("resize", updateDimensions);
    };
    onLoad();
  }, []);


  const updateDimensions = () => {
    setDimensions({ window: { height: window.innerHeight, width: window.innerWidth }});
  };

  const getStyle = () => {
    const h = className == "ya2" ? "50px" : dimensions.window.height;
    const style = {
        height: h
    };
    return style;
  };
	    
  return (
    <header id='home' className={ className } style={getStyle()}>
      {children}
    </header>
  )
}

export default Header
