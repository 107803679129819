import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

const Navigation = ({children}) => {
  return (
    <nav id="nav-wrap" className="opaque">
      <a className='mobile-btn' href='#nav-wrap' title='Show navigation'>Show navigation</a>
      <a className='mobile-btn' href='#' title='Hide navigation'>Hide navigation</a>
      <ul id="nav" className="nav">
        <li>
          <Link to="/#home" className={"smoothscroll"}>
            Home
          </Link>
          </li>
          <li>
          <Link to="/#whatsnew" className={"smoothscroll"}>
            What's New
          </Link>
          </li>
          <li>
          <Link to="/#work" className={"smoothscroll"}>
            Schedule
          </Link>
          </li>
          <li>
          <Link to="/#portfolio" className={"smoothscroll"}>
            Discography
          </Link>
          </li>
          <li>
          <Link to="https://jmo.junkomoriya.com/?page_id=2360&ref=ns" className={"smoothscroll"}>
            DirectSelling
          </Link>
          <Link to="/#directselling" className={"smoothscroll"}>
            Scores
          </Link>
          </li>
          <li>
          <Link to="/#about" className={""}>
            About
          </Link>
          </li>
          <li>
          <Link to="https://ameblo.jp/junkomoriya/" className={"smoothscroll"}>
            Blog
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation
